import { formatCurrency } from '@/utils/commonUtils/libUtils';
import romanize from '@/utils/numbers/romanize';
import { format, parse } from 'date-fns';
import ru from 'date-fns/locale/ru';
import detectDevice from '@/composables/detectDeviceComposable';

const { isTablet, isMobile } = detectDevice();

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  // Верстка для контейнера тултипа и сам контейнер
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.classList.add('graph-tooltip-container');
    tooltipEl.classList.add('potok-text-body-3');

    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipOutcomeHandler = (context, texts) => {
  const { chart, tooltip } = context;
  // Кастомный конфиг, который мы сами создаем в options в компоненте
  const { metaConfig } = chart.config.options;
  // Элемент тултипа
  const tooltipEl = getOrCreateTooltip(chart);

  // Если тултипа нет, скрываем
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = '0';
    return;
  }

  // Создаем элементы и отрисовываем их
  if (tooltip.body) {
    const titleLines = tooltip.title || [];

    // Хедер
    const tableHeader = document.createElement('div');
    tableHeader.classList.add('graph-tooltip-header');

    titleLines.forEach((title) => {
      const icon = document.createElement('span');
      icon.classList.add('icon-calendar');
      icon.alt = 'Icon';
      icon.style.marginRight = '10px';
      icon.width = 14;
      icon.height = 14;

      const headerTextContainer = document.createElement('div');
      let headerText;

      if (metaConfig.tooltipHeaderFormat === 'day') {
        headerText = document.createTextNode(title);
      }

      if (metaConfig.tooltipHeaderFormat === 'month') {
        const columnIndex = tooltip.dataPoints[0].dataIndex;
        const text = metaConfig.defaultLabels[columnIndex];
        const modifiedText = format(
          parse(text, 'dd.MM.yyyy', new Date()),
          'LLLL yyyy',
          { locale: ru },
        );
        headerText = document.createTextNode(modifiedText);
      }

      if (metaConfig.tooltipHeaderFormat === 'quarter') {
        const columnIndex = tooltip.dataPoints[0].dataIndex;
        const text = metaConfig.defaultLabels[columnIndex];
        const modifiedText = format(
          parse(text, 'dd.MM.yyyy', new Date()),
          'Q КВАРТАЛ yyyy',
          { locale: ru },
        );
        // Конвертим арабское число в римское
        const modifiedTextArray = modifiedText.split('');
        modifiedTextArray[0] = romanize(modifiedText[0]);
        const modifiedTextFinal = modifiedTextArray.join('');
        headerText = document.createTextNode(modifiedTextFinal);
      }

      headerTextContainer.classList.add('potok-text-body-3');
      headerTextContainer.classList.add('graph-tooltip-header-text');

      headerTextContainer.appendChild(headerText);
      tableHeader.appendChild(icon);
      tableHeader.appendChild(headerTextContainer);
    });

    // Тело
    const tooltipBody = document.createElement('div');
    tooltipBody.classList.add('graph-tooltip-body');

    const columnIndex = tooltip.dataPoints[0].dataIndex;

    let incomeList = [];

    incomeList = [
      {
        id: 'default',
        text: texts?.default?.name || 'уход в дефолт',
        color: 'rgba(238, 61, 72, 1)',
      },
      {
        id: 'ndfl',
        text: texts?.ndfl?.name || 'удержано ндфл',
        color: 'rgba(255, 193, 30, 1)',
      },
      {
        id: 'withdrawals',
        text: texts?.withdrawals?.name || 'вывод средств',
        color: 'rgba(23, 59, 87, 1)',
      },
      {
        id: 'discounts',
        text: texts?.discounts?.name || 'дисконт при продаже',
        color: 'rgba(255, 122, 47, 1)',
      },
    ];

    // логика полоски пропорций
    const proportionStrip = document.createElement('div');
    proportionStrip.classList.add('graph-tooltip-strip');

    const sum = tooltip.dataPoints[0].dataset.data[columnIndex].indexValue;

    const proportionStripItems = incomeList.map((el) => {
      const percent =
        tooltip.dataPoints[0].dataset.data[columnIndex][el.id] / (sum / 100);
      return {
        backgroundColor: el.color,
        id: el.id,
        percent,
      };
    });

    proportionStripItems.forEach((el) => {
      if (!el.percent) {
        return;
      }

      const proportionStripItemElement = document.createElement('div');
      proportionStripItemElement.classList.add('graph-tooltip-strip-item');
      proportionStripItemElement.style.backgroundColor = el.backgroundColor;
      proportionStripItemElement.style.width = el.percent + '%';
      proportionStripItemElement.style.height = '100%';
      proportionStrip.appendChild(proportionStripItemElement);
    });

    tooltipBody.appendChild(proportionStrip);

    // Продолжение логики наполнения списка в body
    incomeList.forEach((el) => {
      // айтем тела
      const itemContainer = document.createElement('div');
      itemContainer.classList.add('graph-tooltip-item');
      itemContainer.classList.add('mt-2');

      // Кружок в айтеме
      const circle = document.createElement('span');
      circle.classList.add('graph-tooltip-item-circle');
      circle.style.background = el.color;
      circle.style.borderColor = el.color;

      // Лейбл в айтеме
      const bodyLabelContainer = document.createElement('div');
      const labelText = document.createTextNode(el.text);

      // Контейнер для кружка и лейбла
      const bodyItemLeft = document.createElement('div');
      bodyItemLeft.classList.add('graph-tooltip-item-left');
      bodyItemLeft.appendChild(circle);
      bodyItemLeft.appendChild(bodyLabelContainer);

      // value в айтеме
      const bodyValueContainer = document.createElement('div');
      bodyValueContainer.classList.add('graph-tooltip-mobile-value');
      const valueText = document.createTextNode(
        `${formatCurrency(tooltip.dataPoints[0].dataset.data[columnIndex][el.id], 0)} ₽`,
      );

      bodyLabelContainer.appendChild(labelText);
      bodyValueContainer.appendChild(valueText);
      itemContainer.appendChild(bodyItemLeft);
      itemContainer.appendChild(bodyValueContainer);
      tooltipBody.appendChild(itemContainer);
    });

    // Footer
    const tooltipFooter = document.createElement('div');

    if (metaConfig.tooltipFooter) {
      tooltipFooter.classList.add('divider');
      tooltipFooter.classList.add('graph-tooltip-footer-container');

      const tooltipFooterContent = document.createElement('div');
      tooltipFooterContent.classList.add('graph-tooltip-footer-content');

      const tooltipFooterTextContainer = document.createElement('div');

      let footerText;
      footerText = 'всего расходы';
      const tooltipFooterText = document.createTextNode(footerText);

      // Считаем сумму всех айтемов для футера
      const sum = tooltip.dataPoints[0].dataset.data[columnIndex].indexValue;

      // Создаем элемент для суммы и форматируем его
      const tooltipFooterSumContainer = document.createElement('div');
      tooltipFooterSumContainer.classList.add('graph-tooltip-text-bold');
      tooltipFooterSumContainer.classList.add('graph-tooltip-mobile-value');
      const tooltipFooterSum = document.createTextNode(
        `${formatCurrency(sum, 0)} ₽`,
      );

      tooltipFooterTextContainer.appendChild(tooltipFooterText);
      tooltipFooterSumContainer.appendChild(tooltipFooterSum);
      tooltipFooterContent.appendChild(tooltipFooterTextContainer);
      tooltipFooterContent.appendChild(tooltipFooterSumContainer);
      tooltipFooter.appendChild(tooltipFooterContent);
    }

    const tableRoot = tooltipEl;
    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableHeader);
    tableRoot.appendChild(tooltipBody);
    if (metaConfig.tooltipFooter) {
      tableRoot.appendChild(tooltipFooter);
    }
  }

  const { offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = '1';
  let xPixels = 0;

  // Если мобилка или планшет, то из-за маленького экрана, тултип будет менять свое расположение, что бы не часть не пропадала за экраном
  const columnIndex = tooltip.dataPoints[0].dataIndex;

  if (columnIndex >= chart.data.labels.length / 2) {
    if (isTablet.value || isMobile.value) {
      xPixels = -220;
    } else {
      xPixels = -340;
    }
  }
  if (columnIndex === 0) {
    xPixels = -50;
  }
  xPixels += tooltip.caretX;
  tooltipEl.style.left = `${xPixels}px`;
  tooltipEl.style.top = `${positionY + tooltip.caretY}px`;
};

export default externalTooltipOutcomeHandler;
